import { Link } from '@remix-run/react'
import { twMerge } from 'tailwind-merge'
import { appStoreLink, googlePlayLink } from '#app/utils/misc'
import { IzzyLogo } from './icons/izzy-logo'

export const Footer = ({
	hideLinks,
	className,
}: {
	hideLinks?: boolean
	className?: string
}) => {
	return (
		<footer
			className={twMerge(
				'mx-auto max-w-7xl px-6 py-16 xs:px-12 sm:px-24',
				className,
			)}
		>
			<div className="flex flex-col lg:w-full lg:flex-row lg:justify-between">
				<div className="mx-auto my-6 lg:mx-0 lg:my-0 lg:mr-10 lg:flex lg:flex-col ">
					<Link to="/" prefetch="intent">
						<IzzyLogo className="max-lg:mx-auto" />
					</Link>
					<p className="text-sm text-foreground max-lg:text-center">
						IZZY Technologies s.r.o. <br />
						Lihovarská 1847/14, Libeň (Praha 9), 190 00 Praha <br />
						IČO: 17088801, DIČ: CZ17088801
					</p>
					<div className="hidden lg:my-auto lg:block">
						<div className="pb-5 pt-12 text-center text-sm font-medium leading-loose text-gray-800 lg:pb-0 lg:pt-0 lg:text-start">
							Stáhněte si Izzy nyní
						</div>
						<div className="flex gap-4">
							<a href={appStoreLink}>
								<img
									width={135}
									height={44}
									src="/img/app-store.svg"
									alt="app-store"
								/>
							</a>
							<a href={googlePlayLink}>
								<img
									width={148.5}
									height={44}
									src="/img/google-play.svg"
									alt="google-play"
								/>
							</a>
						</div>
					</div>
				</div>
				<div className="mb-12 flex h-9 justify-center gap-6 lg:hidden">
					<a
						href="https://www.facebook.com/IzzyUklid"
						target="_blank"
						rel="noreferrer"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/facebook.svg"
							alt="facebook"
						/>
					</a>
					<a
						href="https://twitter.com/izzy_uklid"
						target="_blank"
						rel="noreferrer"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/twitter.svg"
							alt="twitter"
						/>
					</a>
					<a
						href="https://www.instagram.com/izzy_uklid/"
						target="_blank"
						rel="noreferrer"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/instagram.svg"
							alt="instagram"
						/>
					</a>
					<a
						href="https://www.linkedin.com/company/izzy-%C3%BAklid/"
						target="_blank"
						rel="noreferrer"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/linkedin.svg"
							alt="linkedin"
						/>
					</a>
				</div>
				{!hideLinks && (
					<div className="flex w-full justify-center lg:max-w-fit lg:justify-normal">
						<div className="flex w-full max-w-xl items-start justify-between gap-8 lg:justify-normal">
							<div className="grid gap-2 text-slate-700">
								<h2 className="font-semibold">Odkazy</h2>
								<Link prefetch="intent" to="/how-it-works">
									Jak to funguje
								</Link>
								<Link prefetch="intent" to="/signup?type=provider">
									Stát se paní na úklid
								</Link>
								<Link prefetch="intent" to="/pricing">
									Ceník
								</Link>
								<Link prefetch="intent" to="/about">
									O nás
								</Link>
								<Link prefetch="intent" to="/insurance">
									Pojištění
								</Link>
								<Link prefetch="intent" to="/contact">
									Kontakt
								</Link>
								<Link prefetch="intent" to="/contact#faq-section">
									FAQ
								</Link>
							</div>
							<div className="grid gap-2 text-slate-700">
								<h2 className="font-semibold">Úklidy</h2>
								<Link prefetch="intent" to="/b2c">
									Domácnosti
								</Link>
								<Link prefetch="intent" to="/b2b">
									Firmy
								</Link>
								<Link prefetch="intent" to="/b2b">
									Airbnb a další SVJ
								</Link>
							</div>
						</div>
					</div>
				)}
				<div className="block lg:hidden">
					<div className="pb-5 pt-12 text-center text-sm font-medium leading-loose text-gray-800 lg:pb-0 lg:pt-0 lg:text-start">
						Stáhněte si Izzy nyní
					</div>
					<div className="flex justify-center gap-3">
						<a href={appStoreLink}>
							<img
								width={135}
								height={44}
								src="/img/app-store.svg"
								alt="app-store"
							/>
						</a>
						<a href={googlePlayLink}>
							<img
								width={148.5}
								height={44}
								src="/img/google-play.svg"
								alt="google-play"
							/>
						</a>
					</div>
					<div className="mt-4 flex flex-row justify-center lg:hidden">
						<div className="col-span-2 mx-4 flex items-center justify-center">
							<img src="/img/stripe.png" alt="stripe" width={80} />
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img src="/img/Visa.png" alt="visa" width={50} />
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img src="/img/Mastercard.png" alt="mastercard" width={50} />
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img
								src="/img/googlepay.png"
								alt="google pay"
								width={50}
								height={30}
							/>
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img src="/img/applepay.png" alt="apple pay" width={60} />
						</div>
					</div>
				</div>

				{!hideLinks ? (
					<div className="hidden grid-cols-2 grid-rows-3 lg:grid">
						<div className="col-span-2 flex items-center justify-center">
							<img src="/img/stripe.png" alt="stripe" width={135} />
						</div>
						<div className="flex items-center justify-center">
							<img src="/img/Visa.png" alt="visa" width={80} />
						</div>
						<div className="flex items-center justify-center">
							<img src="/img/Mastercard.png" alt="mastercard" width={80} />
						</div>
						<div className="flex items-center justify-center">
							<img
								src="/img/googlepay.png"
								alt="google pay"
								width={80}
								height={30}
							/>
						</div>
						<div className="flex items-center justify-center">
							<img src="/img/applepay.png" alt="apple pay" width={100} />
						</div>
					</div>
				) : (
					<div className="mt-4 hidden flex-row justify-center lg:flex">
						<div className="col-span-2 mx-4 flex items-center justify-center">
							<img src="/img/stripe.png" alt="stripe" width={80} />
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img src="/img/Visa.png" alt="visa" width={50} />
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img src="/img/Mastercard.png" alt="mastercard" width={50} />
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img
								src="/img/googlepay.png"
								alt="google pay"
								width={50}
								height={30}
							/>
						</div>
						<div className="mx-4 flex items-center justify-center">
							<img src="/img/applepay.png" alt="apple pay" width={60} />
						</div>
					</div>
				)}
			</div>

			<hr className="my-9" />
			<div className="flex w-full justify-between">
				<div className="hidden justify-center gap-4 lg:flex">
					<a
						href="https://www.facebook.com/UklidNaKlik"
						target="_blank"
						rel="noreferrer"
						className="w-6"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/facebook.svg"
							alt="facebook"
						/>
					</a>
					<a
						href="https://twitter.com/izzy_uklid"
						target="_blank"
						rel="noreferrer"
						className="w-6"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/twitter.svg"
							alt="twitter"
						/>
					</a>
					<a
						href="https://www.instagram.com/izzy_uklid/"
						target="_blank"
						rel="noreferrer"
						className="w-6"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/instagram.svg"
							alt="instagram"
						/>
					</a>
					<a
						href="https://www.linkedin.com/company/izzy-%C3%BAklid/"
						target="_blank"
						rel="noreferrer"
						className="w-6"
					>
						<img
							width={24}
							height={24}
							src="/img/socials/linkedin.svg"
							alt="linkedin"
						/>
					</a>
				</div>
				<div className="hidden gap-5 text-base font-medium text-slate-700 xl:flex">
					<div className="inline-flex items-center gap-1">
						<img width={32} height={32} src="/img/award.svg" alt="award" />
						<p className="text-xs">Všechny naše úklidy jsou pojištěné</p>
					</div>
					<div className="inline-flex items-center gap-1">
						<img width={32} height={32} src="/img/lock.svg" alt="lock" />
						<p className="text-xs">Bezpečná on-line platba</p>
					</div>
				</div>
				<div className="flex w-full justify-between text-sm font-medium leading-loose text-gray-900 lg:max-w-fit lg:justify-end lg:gap-8">
					<Link prefetch="intent" to="/tos">
						Obchodní podmínky
					</Link>
					<Link prefetch="intent" to="/privacy">
						Ochrana soukromí
					</Link>
				</div>
			</div>
		</footer>
	)
}
